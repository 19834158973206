import {
  create_new_brainstorm_session,
  delete_session,
  get_brainstorm_sessions,
} from "api";
import { ErrorPage, LoadingPage, Title } from "components";
import { redirect, useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import useSWR, { mutate } from "swr";
import { useState } from "react";
import { Input } from "@/components/styled/input";
import { Select } from "@/components/styled/select";
import useSWRMutation from "swr/mutation";
import { Dialog } from "@/components/styled/dialog";
import { DialogClose } from "@/components/ui/dialog";
import { MdDelete } from "react-icons/md";
import { remove_timestamp } from "@/lib/utils";
import { PageHelp } from "@/components/styled/pageHelp";

const agent_types = [
  { label: "Sociology", value: "sociology" },
  { label: "Computer Science", value: "computer science" },
  { label: "Data Science", value: "data science" },
  { label: "Economics", value: "economics" },
  { label: "Mechnical Engineering", value: "mechanical engineering" },
  { label: "Chemical Engineering", value: "chemical engineering" },
  { label: "Aerospace Engineering", value: "aerospace engineering" },
  { label: "Statistics", value: "statistics" },
  { label: "Mathematics", value: "mathematics" },
  { label: "Civil Engineering", value: "civil engineering" },
  { label: "Medical", value: "medical" },
  { label: "Computer Engineering", value: "computer engineering" },
  { label: "Computer Biology", value: "biology" },
  { label: "Chemistry", value: "chemistry" },
  { label: "Physics", value: "physics" },
];

export default function BrainstormSessionList() {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const {
    data: brainstormSessions,
    isLoading: isGettingBrainstormSessions,
    error: brainstormSessionsError,
  } = useSWR(`get_brainstorm_sessions/${projectId}`, () =>
    get_brainstorm_sessions({ project_id: projectId })
  );

  const {
    isMutating: isCreatingNewBrainstormSession,
    error: createNewBrainstormSession,
    trigger: createBrainstormSession,
  } = useSWRMutation(
    `create_brainstorm_session`,
    () =>
      create_new_brainstorm_session({
        project_id: projectId,
        name: sessionTitle,
        agent_1_background: agent1Background,
        agent_3_background: agent3Background,
      }),
    {
      onSuccess: (d) => {
        const { session_id } = d;
        navigate(
          `/project/${projectId}/ideation-space/brainstorm-session/${session_id}`
        );
        mutate(`get_brainstorm_sessions/${projectId}`);
      },
    }
  );

  const {
    isMutating: isDeletingSession,
    error: deleteSessionError,
    trigger: deleteSession,
  } = useSWRMutation(
    `delete_session`,
    (key, { arg }) =>
      delete_session({
        session_id: arg,
      }),
    {
      onSuccess: () => {
        mutate(`get_brainstorm_sessions/${projectId}`, undefined, {
          revalidate: true,
        });
      },
    }
  );

  const [sessionTitle, setSessionTitle] = useState("");
  const [agent1Background, setAgent1Background] = useState("");
  const [agent3Background, setAgent3Background] = useState("");

  if (isGettingBrainstormSessions) return <LoadingPage />;
  if (brainstormSessionsError) return <ErrorPage />;

  return (
    <div className="bg-white flex flex-col grow rounded-lg h-full p-4 gap-y-2">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Brainstorm Sessions" />
        <PageHelp
          title="Ideation Space"
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <div>
                The Ideation Space is designed to help you refine your research
                idea.
              </div>
              <div>
                <h2 className="font-semibold">Research Idea</h2>
                Here you will tell us a more about what idea you would like to
                get funded.
              </div>
              <div>
                <h2 className="font-semibold">Background Material</h2>
                Enhance our understanding of your idea by searching for and
                linking relevant papers. You can search for papers using Arxiv
                or Semantic Scholar. Note: adding papers here will help us to
                recommend better calls for you in the future.
              </div>
              <div>
                <h2 className="font-semibold">Brainstorming Ideas</h2>
                Use this section to further develop your idea, take notes, and
                explore new perspectives. You can manually add ideas to the
                brainstorming list, or let us generate suggestions for you.
                Ideas are generated based on your Research Idea, Description,
                and any Background Materials you've provided.
              </div>
              <div>
                <h2 className="font-semibold">Brainstorming Sessions</h2>
                With Sessions, you can easily create brainstorming rooms with
                experts from various backgrounds. Each session lasts a few days,
                and after it ends, we'll provide a summary of the discussion.
                You can start new sessions based on previous topics or begin
                with a fresh idea.
              </div>
            </div>
          }
        />
      </div>
      <div className="text-base">
        Welcome to Sessions — your brainstorming copilot. Easily create rooms
        with experts from various fields, with each session lasting just a few
        days. When it's over, we'll provide a quick recap. Build on past ideas
        or start fresh. Give it a try and see what new ideas you come up with.
      </div>
      <div
        className={`flex ${
          brainstormSessions?.length > 0 ? "justify-end" : "justify-center"
        }`}
      >
        <Dialog
          className={`${brainstormSessions?.length > 0 ? "" : "my-auto"}`}
          trigger={
            <span className="btn-fuchsia text-base">Create a new session</span>
          }
          content={
            <div className="flex flex-col gap-y-4">
              <Input
                placeholder="Session Title"
                value={sessionTitle}
                onChange={setSessionTitle}
              />

              <Select
                options={agent_types}
                value={agent1Background}
                placeholder="Researcher 1 Expertise"
                onChange={setAgent1Background}
              />

              <Select
                options={agent_types}
                value={agent3Background}
                placeholder="Researcher 3 Expertise"
                onChange={setAgent3Background}
              />
              <div>
                <button
                  onClick={createBrainstormSession}
                  disabled={!agent1Background || !agent3Background}
                  className="btn-fuchsia"
                >
                  Start Session
                </button>
              </div>
            </div>
          }
        />
      </div>
      {brainstormSessions?.length > 0 && (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Session Name</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Created</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {brainstormSessions.map((session) => (
              <TableRow key={session.session_id} className="group">
                <TableCell
                  className="hover:text-fuchsia hover:underline hover:cursor-pointer"
                  onClick={() =>
                    navigate(
                      `/project/${projectId}/ideation-space/brainstorm-session/${session.session_id}`
                    )
                  }
                >
                  {session.title}
                </TableCell>
                <TableCell>
                  {session.finished ? "Done" : "In Progress"}
                </TableCell>
                <TableCell>{remove_timestamp(session.creation_time)}</TableCell>
                <TableCell onClick={(e) => e.stopPropagation()}>
                  <Dialog
                    className="hover:text-fuchsia invisible group-hover:visible"
                    trigger={<MdDelete className="w-4 h-4" />}
                    title={
                      <div>Are you sure you want to delete this session?</div>
                    }
                    content={
                      <div>
                        <div className="flex justify-between mt-8">
                          <DialogClose asChild>
                            <button className="text-zinc-600 hover:underline">
                              Cancel
                            </button>
                          </DialogClose>
                          <button
                            className="text-red-600 hover:underline"
                            onClick={() => deleteSession(session.session_id)}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
}
