import { Input } from "@/components/ui/input";

function i({
  value,
  onChange,
  type,
  placeholder,
  maxLength,
  readOnly,
  min,
  max,
  onBlur,
  forwardref,
  ...props
}) {
  return (
    <Input
      type={type}
      placeholder={placeholder}
      value={value}
      onBlur={(e) => onBlur?.()}
      onChange={(e) => onChange?.(e.target.value)}
      maxLength={maxLength}
      readOnly={readOnly}
      min={min}
      max={max}
      ref={forwardref}
      {...props}
    />
  );
}

export { i as Input };
