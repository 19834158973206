import { delete_required_file, edit_required_file } from "api";
import { EditableField, EditableTextInput } from "components";

import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import { FaExpand } from "react-icons/fa";
import { Dialog } from "@/components/styled/dialog";
import { TableCell, TableRow } from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/styled/input";
import { Textarea } from "@/components/styled/textarea";

export default function File({
  file_id,
  file_info,
  completed,
  description,
  projectId,
  hasEditorAccess,
}) {
  const [isFullMenuOpen, setIsFullMenuOpen] = useState(false);

  const { error: toggleCompleteError, trigger: toggleComplete } =
    useSWRMutation(
      `toggle_complete`,
      () =>
        edit_required_file({
          project_id: projectId,
          file_id: file_id,
          complete: !completed,
        }),
      {
        onSuccess: () => {
          mutate(
            `get_required_files/${projectId}`,
            (d) =>
              d.map((file) =>
                file.file_id === file_id
                  ? { ...file, completed: !completed }
                  : file
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: updateFileInfoError, trigger: updateFileInfo } =
    useSWRMutation(
      `update_file_info`,
      (key, { arg }) => {
        edit_required_file({
          project_id: projectId,
          file_id: file_id,
          file_info: arg,
        });
        return arg;
      },
      {
        onSuccess: (new_file_info) => {
          mutate(
            `get_required_files/${projectId}`,
            (d) =>
              d.map((file) =>
                file.file_id === file_id
                  ? { ...file, file_info: new_file_info }
                  : file
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: updateDescriptionError, trigger: updateDescription } =
    useSWRMutation(
      `update_description`,
      (key, { arg }) => {
        if (arg === description) return arg;
        edit_required_file({
          project_id: projectId,
          file_id: file_id,
          description: arg,
        });
        return arg;
      },
      {
        onSuccess: (new_description) => {
          mutate(
            `get_required_files/${projectId}`,
            (d) =>
              d.map((file) =>
                file.file_id === file_id
                  ? { ...file, description: new_description }
                  : file
              ),
            { revalidate: false }
          );
        },
      }
    );

  const { error: deleteRequiredFileError, trigger: deleteRequiredFile } =
    useSWRMutation(
      `delete_required_file`,
      () => delete_required_file({ project_id: projectId, file_id: file_id }),
      {
        onSuccess: () => {
          mutate(
            `get_required_files/${projectId}`,
            (d) => d.filter((file) => file.file_id !== file_id),
            { revalidate: false }
          );
        },
      }
    );

  return (
    <TableRow key={file_id} className="group">
      <TableCell className="relative">
        <Input
          placeholder={"New file"}
          value={file_info || ""}
          onChange={updateFileInfo}
          maxLength={250}
          readOnly={!hasEditorAccess}
        />

        <Dialog
          className="absolute top-2 h-8 right-0 text-sm font-bold invisible group-hover:visible bg-light-grey hover:opacity-80 rounded-md px-1 flex items-center gap-x-1"
          trigger={
            <>
              <FaExpand /> OPEN
            </>
          }
          content={
            <div>
              <Input
                placeholder={"New file"}
                value={file_info || ""}
                onChange={updateFileInfo}
                maxLength={250}
                readOnly={!hasEditorAccess}
              />
              <table className="table-auto text-base border-separate border-spacing-x-2 my-2">
                <tbody>
                  <tr>
                    <td className="font-semibold">Completed</td>
                    <td>
                      <Checkbox
                        checked={completed}
                        onClick={toggleComplete}
                        disabled={!hasEditorAccess}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <Textarea
                placeholder={"Enter a description..."}
                value={description || ""}
                onChange={updateDescription}
                readOnly={!hasEditorAccess}
              />
              <button
                onClick={deleteRequiredFile}
                className="text-red-500 flex items-center mt-8"
                disabled={!hasEditorAccess}
              >
                <MdDelete /> Delete
              </button>
            </div>
          }
        />
      </TableCell>
      <TableCell className="text-center">
        <Checkbox
          checked={completed}
          onClick={toggleComplete}
          disabled={!hasEditorAccess}
        />
      </TableCell>
    </TableRow>
  );
}
