import { Link, Outlet, redirect, useNavigate } from "react-router-dom";

import { MdOutlineLogout } from "react-icons/md";

import InitiumLogo from "assets/initium-logos/primary-horizontal-colored-black.svg";
import { useAuthStore } from "stores/useAuthStore";

import { logout } from "api";
import { useState } from "react";

export const LayoutLoader = async () => {
  let refreshToken = useAuthStore.getState().refreshToken;
  if (!refreshToken) {
    return redirect("/login");
  }
  return null;
};

/**
 *
 * @param {*} navbar A react component to render the navigation list
 * @returns
 */
export default function Layout({ navbar }) {
  const navigate = useNavigate();
  // let a = useAuthStore();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  return (
    <div className="h-screen w-screen max-h-screen relative overflow-auto bg-pastel-blue flex flex-row gap-x-4 p-4 justify-center">
      <div className="w-56 flex flex-col shrink-0 h-full justify-between items-center max-h-screen overflow-y-scroll">
        <div className="w-full flex flex-col items-center overflow-y-scroll">
          <Link to="/" className="w-fit pb-6">
            <img
              className="w-60 aspect-auto"
              src={InitiumLogo}
              alt="Initium Logo"
              onClick={() => {
                navigate("/");
              }}
            />
          </Link>
          {navbar}
        </div>
        <div className="w-full px-3 flex justify-center">
          <button
            className="flex items-center border-2 border-offblack rounded-md gap-x-2 hover:border-fuchsia hover:text-fuchsia px-4 py-2  text-2xl transition"
            onBlur={() => setIsLoggingOut(false)}
            onClick={() => {
              if (isLoggingOut) {
                logout();
                useAuthStore.getState().logout();
                return navigate("/login");
              } else {
                setIsLoggingOut(true);
              }
            }}
          >
            {isLoggingOut ? <>Are you sure?</> : <>Logout</>}
            <MdOutlineLogout />
          </button>
        </div>
      </div>
      <div className="flex h-full grow overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
}
