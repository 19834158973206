import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import useSWR from "swr";
import { get_project } from "api";

export default function ProjectNavbar() {
  const location = useLocation();
  const { projectId } = useParams();
  const { pathname } = location;
  const link = `/project/${projectId}`;

  const {
    data: project,
    isLoading: isProjectLoading,
    error: projectError,
  } = useSWR(
    `get_project/${projectId}`,
    async () => await get_project({ project_id: projectId })
  );

  const isOnDashboard = pathname.split("/").length === 3;

  if (isProjectLoading) return <></>;
  if (projectError)
    return (
      <span className="text-red-500">Error loading project information.</span>
    );

  return (
    <nav className="flex flex-col gap-y-2 w-full overflow-y-scroll px-1">
      <Link
        to={"/"}
        className="flex w-full aria-hidden:hidden"
        aria-hidden={!isOnDashboard}
      >
        <button className="flex w-full justify-center items-center nav-offwhite font-semibold px-4">
          <FaChevronLeft /> Back to Home
        </button>
      </Link>
      <Link
        to={link}
        className="flex w-full aria-hidden:hidden"
        aria-hidden={isOnDashboard}
      >
        <button className="flex w-full justify-center items-center nav-offwhite font-semibold px-4">
          <FaChevronLeft /> Dashboard
        </button>
      </Link>

      <Accordion
        className="overflow-y-scroll pb-8"
        type="multiple"
        defaultValue={[
          "ideation-space",
          "grant-space",
          "admin-space",
          "review-space",
          "write-space",
        ]}
      >
        {project.tiles?.find((tile) => tile.type === "idea_tile") && (
          <AccordionItem value="ideation-space">
            <AccordionTrigger className="px-2 py-1 text-base underline font-semibold rounded-2xl">
              Ideation Space
            </AccordionTrigger>
            <AccordionContent className="p-1 flex flex-col gap-y-2">
              <NavLink className="nav-link" to={link + "/ideation-space"} end>
                Research Idea
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/ideation-space/background-material"}
                end
              >
                Background Material
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/ideation-space/brainstorming"}
                end
              >
                Brainstorm Ideas
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/ideation-space/brainstorm-session"}
              >
                Brainstorm Sessions
              </NavLink>
            </AccordionContent>
          </AccordionItem>
        )}
        {project.tiles?.find((tile) => tile.type === "grant_tile") && (
          <AccordionItem value="grant-space">
            <AccordionTrigger className="px-2 py-1 text-base underline font-semibold rounded-2xl">
              Grant Space
            </AccordionTrigger>
            <AccordionContent className="p-1 flex flex-col gap-y-2">
              <NavLink
                className="nav-link"
                to={link + "/grant-space/selected-call"}
                end
              >
                Selected Call
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/grant-space/recommended-calls"}
                end
              >
                Recommended Calls
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/grant-space/search-calls"}
                end
              >
                Search for Calls
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/grant-space/bookmarks"}
                end
              >
                Bookmarks
              </NavLink>
            </AccordionContent>
          </AccordionItem>
        )}

        {project.tiles?.find((tile) => tile.type === "admin_tile") && (
          <AccordionItem value="admin-space">
            <AccordionTrigger className="px-2 py-1 text-base underline font-semibold rounded-2xl">
              Admin Space
            </AccordionTrigger>
            <AccordionContent className="p-1 flex flex-col gap-y-2">
              <NavLink className="nav-link" to={link + "/admin-space"} end>
                Action Items
              </NavLink>
              <NavLink
                className="nav-link"
                to={link + "/admin-space/document-checklist"}
                end
              >
                Document Checklist
              </NavLink>
            </AccordionContent>
          </AccordionItem>
        )}
        <AccordionItem value="review-space">
          <AccordionTrigger className="px-2 py-1 text-base underline font-semibold rounded-2xl">
            Review Space
          </AccordionTrigger>
          <AccordionContent className="p-1 flex flex-col gap-y-2">
            <NavLink className="nav-link" to={link + "/review-space"}>
              Proposal Reviews
            </NavLink>
          </AccordionContent>
        </AccordionItem>
        {/* <AccordionItem value="write-space">
          <AccordionTrigger className="px-2 py-1 text-base underline font-semibold rounded-2xl">
            Write Space
          </AccordionTrigger>
          <AccordionContent className="p-1 flex flex-col gap-y-2">
            <NavLink className="nav-link" to={link + "/write-space"} end>
              Document Brainstorm
            </NavLink>
          </AccordionContent>
        </AccordionItem> */}
      </Accordion>
    </nav>
  );
}
