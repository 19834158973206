import { PageHelp } from "@/components/styled/pageHelp";
import { get_profile, get_project } from "api";
import { ErrorPage, LoadingPage, Title } from "components";
import useSWR from "swr";
import ChooseACall from "./choose-a-call/ChooseACall";
import { useParams } from "react-router-dom";
import SelectedCall from "./selected-call/SelectedCall";

export default function SelectCall() {
  const { projectId } = useParams();

  const {
    data: project,
    isLoading: isProjectLoading,
    error: isProjectError,
  } = useSWR(`get_project/${projectId}`, () =>
    get_project({ project_id: projectId })
  );

  const {
    data: profile,
    isLoading: isProfileLoading,
    error: isProfileError,
  } = useSWR(`get_profile`, () => get_profile());

  if (isProjectLoading || isProfileLoading) return <LoadingPage />;
  if (isProjectError || isProfileError) return <ErrorPage />;

  return (
    <div className="bg-white grow rounded-lg h-full p-4">
      <div className="flex flex-col h-full gap-y-4">
        <div className="flex w-full justify-between">
          <Title className="text-4xl" value="Selected Call" />
          <PageHelp
            title="Grant Space"
            content={
              <div className="flex flex-col gap-y-4 text-black">
                <div>
                  The Grant Space is dedicated to discovering and managing
                  funding opportunities.
                </div>

                <div>
                  <h2 className="font-semibold">Selected Call</h2>
                  View all details about the grant you are applying for. If you
                  haven't selected a call yet, you can do so here.
                </div>
                <div>
                  <h2 className="font-semibold">Recommend Calls</h2>
                  Using the information you provided in the Research Idea and
                  Background Material sections of the Ideation Space, we will
                  suggest funding opportunities we think may be relevant to you.
                </div>
                <div>
                  <h2 className="font-semibold">Search For Calls</h2>A search
                  tool for finding calls.
                </div>
                <div>
                  <h2 className="font-semibold">Bookmarked Calls</h2>
                  Keep track of important calls by bookmarking them. This
                  section shows all calls bookmarked by you and any other
                  collaborators on the project.
                </div>
              </div>
            }
          />
        </div>
        {project.selected_grant_id ? (
          <SelectedCall
            projectId={projectId}
            grantId={project.selected_grant_id}
          />
        ) : (
          <ChooseACall projectId={projectId} />
        )}
      </div>
    </div>
  );
}
