import Markdown from "react-markdown";
export default function AgentFeedback({ url, agent_feedback }) {
  return (
    <div className="flex w-full h-full gap-x-2 pb-24">
      <object className="h-full" data={url} height="500" width="100%"></object>

      <div className="flex flex-col h-full gap-y-16 overflow-y-scroll text-sm w-6/12">
        {[
          agent_feedback.map((feedback, i) => (
            <div className="bg-beige p-2 rounded-md" key={i}>
              <div className="text-lg">{feedback.reviewer}</div>
              <div>{feedback.title}</div>
              <div>Page {feedback.page}</div>
              <Markdown>{feedback.feedback}</Markdown>
            </div>
          )),
        ]}
      </div>
    </div>
  );
}
