import { Dialog } from "@/components/styled/dialog";
import { ExternalLink } from "@/components/styled/external-link";
import { DialogClose } from "@/components/ui/dialog";
import { remove_timestamp } from "@/lib/utils";
import { delete_selected_call, get_call_info } from "api";
import { ErrorPage, LoadingPage } from "components";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function SelectedCall({ projectId, grantId }) {
  const {
    data: grant,
    isLoading: isGrantLoading,
    error: isGrantError,
  } = useSWR(`get_grant_info/${grantId}`, () =>
    get_call_info({ call_id: grantId })
  );

  const {
    trigger: deleteProjectCall,
    isMutating: isDeletingProject,
    error: deleteProjectError,
  } = useSWRMutation(
    "delete_project_call",
    () => delete_selected_call({ project_id: projectId }),
    {
      onSuccess: () => {
        mutate(`get_project/${projectId}`);
      },
    }
  );

  if (isGrantLoading) return <LoadingPage />;
  if (isGrantError) return <ErrorPage />;

  const { agency, close_date, description, posted_date, title, url } = grant;
  const newCloseDate = remove_timestamp(close_date) || "Unavailable";
  const newPostedDate = remove_timestamp(posted_date) || "Unavailable";

  return (
    <div>
      Your currently selected call:
      <Dialog
        trigger={
          <div className="flex flex-col border-[1px] rounded-lg p-4 gap-y-2 hover:border-fuchsia text-left">
            <div>
              {url ? (
                <ExternalLink url={url}> {title} </ExternalLink>
              ) : (
                <span className="text-2xl underline font-semibold">
                  {title}
                </span>
              )}
            </div>
            <div>{agency}</div>
            <div className="text-base">
              <strong>Posted</strong>: {newPostedDate}
            </div>
            <div className="text-base">
              <strong>Closes</strong>: {newCloseDate}
            </div>
            <div className="break-wordsp-2 text-base">
              {description.substring(0, 300) + "..."}
            </div>
          </div>
        }
        contentClassName="max-w-4xl max-h-screen overflow-y-scroll"
        content={
          <div className="text-black flex flex-col gap-y-2">
            <div>
              {url ? (
                <ExternalLink url={url}>{title}</ExternalLink>
              ) : (
                <span className="text-2xl underline font-semibold">
                  {title}
                </span>
              )}
            </div>
            <div>{agency}</div>
            <div>Posted: {newPostedDate}</div>
            <div>Closes: {newCloseDate}</div>
            <div className="break-wordsp-2 text-base">{description}</div>
          </div>
        }
      />
      <Dialog
        trigger={
          <span className="text-red-500 hover:underline">
            Remove the selected call
          </span>
        }
        title="Are you sure?"
        contentClassName="max-w-xl max-h-screen"
        content={
          <div className="text-black flex flex-col gap-y-4">
            <div>
              By removing this call, you may need to update the relevant data in
              the admin space.
            </div>
            <div className="flex justify-between">
              <DialogClose asChild>
                <button className="text-zinc-600 hover:underline">Cancel</button>
              </DialogClose>
              <button
                onClick={deleteProjectCall}
                className="text-red-500 hover:underline"
              >
                Remove Call
              </button>
            </div>
            <div>
              {deleteProjectError && (
                <span className="text-red-500">
                  There was an issue removing the selected call from this
                  project.
                </span>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
}
