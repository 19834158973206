import { HiUserCircle } from "react-icons/hi2";
import Markdown from "react-markdown";

export default function UserChatMessage({ sender, message }) {
  return (
    <div className="w-2/3 self-end flex justify-end items-end gap-x-2">
      <div className="bg-blue text-white flex flex-col px-4 py-1 rounded-2xl rounded-ee-none text-sm">
        <Markdown>{message}</Markdown>
      </div>
      <div className="rounded-full bg-blue text-white h-fit w-fit translate-y-1/2">
        <HiUserCircle className="w-8 h-8" />
      </div>
    </div>
  );
}
