import { useState } from "react";
import { Title, LoadingPage, LoadingSpinner, ErrorPage } from "components";
import { get_general, update_general, get_project, get_profile } from "api";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { useParams } from "react-router-dom";
import { Input } from "@/components/styled/input";
import { Textarea } from "@/components/styled/textarea";
import { PageHelp } from "@/components/styled/pageHelp";

export default function General() {
  const { projectId } = useParams();

  const {
    data: project,
    isLoading: isProjectLoading,
    error: isProjectError,
  } = useSWR(`get_project/${projectId}`, () =>
    get_project({ project_id: projectId })
  );

  const {
    data: profile,
    isLoading: isProfileLoading,
    error: isProfileError,
  } = useSWR(`get_profile`, () => get_profile());

  const {
    data,
    isLoading,
    error: isError,
    mutate: mutateGeneral,
  } = useSWR(
    `/project/idea/general/${projectId}`,
    () => get_general({ project_id: projectId, tile_id: 4 }),
    {
      onSuccess: (d) => {
        if (d.idea) {
          setTempIdea(d.idea);
        }
        if (d.description) {
          setTempDescription(d.description);
        }
      },
    }
  );

  const [tempIdea, setTempIdea] = useState(data ? data.idea : "");
  const [tempDescription, setTempDescription] = useState(
    data ? data.description : ""
  );

  const {
    isMutating,
    error: isUpdateError,
    trigger: updateGeneralInformation,
  } = useSWRMutation(
    `update_general`,
    async () => {
      await update_general({
        project_id: projectId,
        idea: tempIdea,
        description: tempDescription,
      });
    },
    {
      onSuccess: (d) => {
        mutateGeneral(
          (data) => {
            return { ...data, description: tempDescription, idea: tempIdea };
          },
          { revalidate: false }
        );
        mutate(
          `get_project/${projectId}`,
          (d) => {
            if (!d) return;
            const index = d.tiles.findIndex((t) => t.type === "idea_tile");
            d.tiles[index].idea = tempIdea;
            return { ...d };
          },
          { revalidate: false }
        );
      },
    }
  );

  if (isLoading || isProjectLoading || isProfileLoading) return <LoadingPage />;
  if (isError || isProjectError || isProfileError) return <ErrorPage />;

  // if both nullish, then equal
  const isInputsEqual =
    ((!data?.idea && !tempIdea) || data?.idea === tempIdea) &&
    ((!data?.description && !tempDescription) ||
      data?.description === tempDescription);
  const hasEditorAccess =
    project?.users.find((p) => p.user_id === profile.user_id).relationship !==
    "viewer";

  return (
    <div className="bg-white grow rounded-lg h-full p-4">
      <div className="flex flex-col gap-y-2">
        <div className="flex justify-between">
          <Title className="text-4xl" value="Research Idea" />
          <PageHelp
            title="Ideation Space"
            content={
              <div className="flex flex-col gap-y-4 text-black">
                <div>
                  The Ideation Space is designed to help you refine your
                  research idea.
                </div>
                <div>
                  <h2 className="font-semibold">Research Idea</h2>
                  Here you will tell us a more about what idea you would like to
                  get funded.
                </div>
                <div>
                  <h2 className="font-semibold">Background Material</h2>
                  Enhance our understanding of your idea by searching for and
                  linking relevant papers. You can search for papers using Arxiv
                  or Semantic Scholar. Note: adding papers here will help us to
                  recommend better calls for you in the future.
                </div>
                <div>
                  <h2 className="font-semibold">Brainstorming Ideas</h2>
                  Use this section to further develop your idea, take notes, and
                  explore new perspectives. You can manually add ideas to the
                  brainstorming list, or let us generate suggestions for you.
                  Ideas are generated based on your Research Idea, Description,
                  and any Background Materials you've provided.
                </div>
                <div>
                  <h2 className="font-semibold">Brainstorming Sessions</h2>
                  With Sessions, you can easily create brainstorming rooms with
                  experts from various backgrounds. Each session lasts a few
                  days, and after it ends, we'll provide a summary of the
                  discussion. You can start new sessions based on previous
                  topics or begin with a fresh idea.
                </div>
              </div>
            }
          />
        </div>

        <div className="max-w-2xl flex flex-col gap-y-2">
          <div className="font-semibold underline">Research Idea</div>
          <Input
            placeholder="Your idea in a sentence"
            value={tempIdea}
            onChange={setTempIdea}
            maxLength="250"
            readOnly={!hasEditorAccess}
          />
          <div className="flex justify-end text-sm font-bold">
            {tempIdea?.length || 0} / 250
          </div>
        </div>
        <div className="max-w-2xl flex flex-col gap-y-2">
          <span className="font-semibold underline">Description</span>

          <Textarea
            className="min-h-96"
            placeholder="A longer one-two paragraph description of your idea"
            value={tempDescription}
            onChange={setTempDescription}
            maxLength="2500"
            readOnly={!hasEditorAccess}
          />
          <div className="flex justify-end text-sm font-bold">
            {tempDescription?.length || 0} / 2,500
          </div>
        </div>
        <div className="flex flex-col">
          <div>
            {isMutating ? (
              <button
                disabled={true}
                className="btn-fuchsia flex items-center gap-x-2"
              >
                Updating <LoadingSpinner />
              </button>
            ) : (
              <button
                className="btn-fuchsia"
                hidden={isInputsEqual}
                onClick={updateGeneralInformation}
                disabled={isInputsEqual}
              >
                Save
              </button>
            )}
          </div>
          {isUpdateError && (
            <span className="text-red-500">
              There was an error updating your ideas. Please try again.
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
