import {
  finalize_session,
  get_brainstorm_session_info,
  get_brainstorm_session_messages,
  send_brainstorm_session_message,
} from "api";
import { ErrorPage, LoadingPage, LoadingSpinner, Title } from "components";
import { useEffect, useRef, useState } from "react";
import { BsFillSendFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import UserChatMessage from "./UserChatMessage";
import InitiumChatMessage from "./InitiumChatMessage";
import EditBrainstormSessionInfo from "./EditBrainstormSessionInfo";
import CreateFollowupSession from "./CreateFollowupSession";
import { Tabs } from "@/components/styled/tabs";
import { FaEllipsisV } from "react-icons/fa";
import { PageHelp } from "@/components/styled/pageHelp";
import { FaArrowLeft } from "react-icons/fa";

export default function BrainstormSession() {
  const { projectId, sessionId } = useParams();

  const chatWindow = useRef(null);
  const [message, setMessage] = useState("");

  const {
    data: sessionInfo,
    isLoading: isGettingSessionInfo,
    error: sessionInfoError,
    isValidating: isValidatingSessionInfo,
  } = useSWR(`get_brainstorm_session_info/${sessionId}`, () =>
    get_brainstorm_session_info({ session_id: sessionId })
  );

  const {
    data: messages,
    isLoading: isGettingBrainstormSessionsMessages,
    error: brainstormSessionsMessagesError,
  } = useSWR(`get_brainstorm_session_messages/${sessionId}`, () =>
    get_brainstorm_session_messages({ session_id: sessionId })
  );

  const {
    isMutating: isSendingMessage,
    error: sendMessageError,
    trigger: sendMessage,
  } = useSWRMutation(
    `create_brainstorm_session`,
    () =>
      send_brainstorm_session_message({
        session_id: sessionId,
        message: message,
      }),
    {
      onSuccess: (d) => {
        mutate(
          `get_brainstorm_session_messages/${sessionId}`,
          (messages) => [...d, ...messages],
          { revalidate: false }
        );
      },
    }
  );

  const {
    isMutating: isFinalizingSession,
    error: finalizeSessionError,
    trigger: finalizeSession,
  } = useSWRMutation(
    `finalize_session`,
    () =>
      finalize_session({
        session_id: sessionId,
      }),
    {
      onSuccess: () => {
        mutate(`get_brainstorm_sessions/${projectId}`);
        mutate(`get_brainstorm_session_info/${sessionId}`);
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSendingMessage) return;

    mutate(
      `get_brainstorm_session_messages/${sessionId}`,
      (messages) => [{ sender: "user", message: message }, ...messages],
      { revalidate: false }
    );
    sendMessage(message);
    setMessage("");
  };

  useEffect(() => {
    if (chatWindow.current) {
      chatWindow.current.scrollTop = chatWindow.current.scrollHeight;
    }
  }, [messages]);

  if (
    isFinalizingSession ||
    isGettingBrainstormSessionsMessages ||
    isValidatingSessionInfo ||
    isGettingSessionInfo
  )
    return <LoadingPage />;
  if (sessionInfoError || brainstormSessionsMessagesError) return <ErrorPage />;
  if (!sessionInfo) return <></>;

  const {
    creator_name,
    agent_1_background,
    agent_3_background,
    title,
    finished,
    summary,
    requestor_is_creator,
  } = sessionInfo;

  return (
    <div className="bg-white grow rounded-lg flex flex-col p-4 w-full gap-y-4 h-full">
      <div className="border-b-2 border-fuchsia">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-x-2">
            <Link
              to={`/project/${projectId}/ideation-space/brainstorm-session`}
              className="hover:text-fuchsia"
            >
              <FaArrowLeft />
            </Link>
            <EditBrainstormSessionInfo
              projectId={projectId}
              sessionId={sessionId}
              title={title}
              trigger={<div className="text-2xl rounded-md">{title}</div>}
            />
          </div>
          <div className="flex items-center">
            {!finished && requestor_is_creator && (
              <button
                className="btn-fuchsia text-base"
                disabled={isFinalizingSession}
                onClick={finalizeSession}
              >
                Finalize Session
              </button>
            )}
            <EditBrainstormSessionInfo
              projectId={projectId}
              sessionId={sessionId}
              title={title}
              trigger={<FaEllipsisV />}
            />
            <PageHelp
              title="Ideation Space"
              content={
                <div className="flex flex-col gap-y-4 text-black">
                  <div>
                    The Ideation Space is designed to help you refine your
                    research idea.
                  </div>
                  <div>
                    <h2 className="font-semibold">Research Idea</h2>
                    Here you will tell us a more about what idea you would like
                    to get funded.
                  </div>
                  <div>
                    <h2 className="font-semibold">Background Material</h2>
                    Enhance our understanding of your idea by searching for and
                    linking relevant papers. You can search for papers using
                    Arxiv or Semantic Scholar. Note: adding papers here will
                    help us to recommend better calls for you in the future.
                  </div>
                  <div>
                    <h2 className="font-semibold">Brainstorming Ideas</h2>
                    Use this section to further develop your idea, take notes,
                    and explore new perspectives. You can manually add ideas to
                    the brainstorming list, or let us generate suggestions for
                    you. Ideas are generated based on your Research Idea,
                    Description, and any Background Materials you've provided.
                  </div>
                  <div>
                    <h2 className="font-semibold">Brainstorming Sessions</h2>
                    With Sessions, you can easily create brainstorming rooms
                    with experts from various backgrounds. Each session lasts a
                    few days, and after it ends, we'll provide a summary of the
                    discussion. You can start new sessions based on previous
                    topics or begin with a fresh idea.
                  </div>
                </div>
              }
            />
          </div>
        </div>
        <div className="text-base text-ellipsis">
          <span className="">{creator_name}, </span>
          <span className="capitalize">
            Researcher 1 ({agent_1_background})
          </span>
          <span>, </span>
          <span className="capitalize">
            Researcher 3 ({agent_3_background})
          </span>
        </div>
      </div>
      {finished ? (
        <Tabs
          defaultValue="summary"
          tabs={[
            {
              label: "Summary",
              value: "summary",
              content: (
                <div className="text-base bg-pastel-blue rounded-md p-4 overflow-y-scroll">
                  {summary}
                </div>
              ),
            },
            {
              label: "Messages",
              value: "messages",
              content: (
                <>
                  <div className="text-base">
                    Note: These messages will disappear after a while.
                  </div>
                  <div
                    ref={chatWindow}
                    className="h-full grow flex flex-col gap-y-4 overflow-y-scroll scroll-smooth p-4 mb-16"
                  >
                    {messages
                      .slice(0)
                      .reverse()
                      .map((message, index) =>
                        message.sender.startsWith("Researcher") ? (
                          <InitiumChatMessage
                            key={index}
                            {...message}
                            creator_name={creator_name}
                          />
                        ) : (
                          <UserChatMessage
                            key={index}
                            {...message}
                            className="bg-gray-200"
                          />
                        )
                      )}
                  </div>
                </>
              ),
            },
          ]}
        />
      ) : (
        <div
          ref={chatWindow}
          className="grow flex flex-col gap-y-4 overflow-y-scroll scroll-smooth p-4"
        >
          {messages
            .slice(0)
            .reverse()
            .map((message, index) =>
              message.sender.startsWith("Researcher") ? (
                <InitiumChatMessage
                  key={index}
                  {...message}
                  creator_name={creator_name}
                />
              ) : (
                <UserChatMessage
                  key={index}
                  {...message}
                  className="bg-gray-200"
                />
              )
            )}
          {isSendingMessage && (
            <div className="w-2/3 flex gap-x-2 items-end">
              <div className="rounded-full bg-pastel-blue border-2 h-fit w-fit translate-y-1/2">
                <div className="w-8 h-8"></div>
              </div>
              <div className="bg-pastel-blue flex items-center gap-x-2 px-4 py-1 rounded-2xl rounded-es-none text-sm">
                <LoadingSpinner /> Loading...
              </div>
            </div>
          )}
        </div>
      )}
      {!finished && requestor_is_creator && (
        <form className="flex w-full gap-x-2" onSubmit={handleSubmit}>
          <input
            className="border-offblack border-2 rounded-lg grow bg-light-grey px-2"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            disabled={message.length === 0 || isSendingMessage}
            className="btn-fuchsia flex gap-x-2 items-center"
          >
            Send
            <BsFillSendFill />
          </button>
        </form>
      )}
      {finished && requestor_is_creator && (
        <CreateFollowupSession sessionId={sessionId} projectId={projectId} />
      )}
    </div>
  );
}
