import { Dialog } from "@/components/styled/dialog";
import { Input } from "@/components/styled/input";
import { delete_session, update_session_title } from "api";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function EditBrainstormSessionInfo({
  projectId,
  sessionId,
  title,
  trigger,
}) {
  const navigate = useNavigate();
  const [sessionTitle, setSessionTitle] = useState(title);

  const {
    isMutating: isUpdatingSessionTitle,
    error: updateSessionTitleError,
    trigger: updateSessionTitle,
  } = useSWRMutation(
    `update_session_title`,
    () =>
      update_session_title({
        session_id: sessionId,
        name: sessionTitle,
      }),
    {
      onSuccess: () => {
        mutate(`get_brainstorm_sessions/${projectId}`);
      },
    }
  );

  const {
    isMutating: isDeletingSession,
    error: deleteSessionError,
    trigger: deleteSession,
  } = useSWRMutation(
    `delete_session`,
    () =>
      delete_session({
        session_id: sessionId,
      }),
    {
      onSuccess: () => {
        mutate(`get_brainstorm_sessions/${projectId}`, undefined, {
          revalidate: true,
        });
        navigate(`/project/${projectId}/ideation-space/brainstorm-session`);
      },
    }
  );

  const handleUpdateSessionTitle = () => {
    if (sessionTitle === title) return;
    updateSessionTitle();
  };

  const handleDeleteSession = () => {
    deleteSession();
  };

  return (
    <Dialog
      className={"hover:text-fuchsia"}
      trigger={trigger}
      content={
        <div className="flex flex-col gap-y-8">
          <div>
            <div className="font-semibold">Session Title</div>
            <Input
              onChange={(value) => setSessionTitle(value)}
              value={sessionTitle}
              onBlur={handleUpdateSessionTitle}
            />
          </div>
          <div>
            <button
              className="flex gap-x-1 items-center text-red-500 hover:underline"
              onClick={handleDeleteSession}
            >
              <MdDelete /> Delete Session
            </button>
          </div>
        </div>
      }
    />
  );
}
