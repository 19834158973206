import {
  get_project,
  get_profile,
  get_required_files,
  add_required_file,
  recommend_required_files,
} from "api";
import { ErrorPage, LoadingPage, Title } from "components";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import File from "./File";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { GoPlus } from "react-icons/go";
import { Tabs } from "@/components/styled/tabs";
import { PageHelp } from "@/components/styled/pageHelp";
import AddRecommendedFiles from "./AddRecommendedFiles";

export default function DocumentChecklist() {
  const { projectId } = useParams();

  const {
    data: project,
    isLoading: isProjectLoading,
    error: projectError,
  } = useSWR(
    `get_project/${projectId}`,
    async () => await get_project({ project_id: projectId })
  );

  const {
    data: profile,
    isLoading: isProfileLoading,
    error: profileError,
  } = useSWR(`get_profile`, get_profile);

  const {
    data: requiredFiles,
    isLoading: isRequiredFilesLoading,
    error: requiredFilesError,
  } = useSWR(`get_required_files/${projectId}`, () =>
    get_required_files({ project_id: projectId })
  );

  const { error: addRequiredFileError, trigger: addRequiredFile } =
    useSWRMutation(
      `add_required_file`,
      () => add_required_file({ project_id: projectId }),
      {
        onSuccess: () => {
          mutate(`get_required_files/${projectId}`);
        },
      }
    );

  if (isRequiredFilesLoading || isProjectLoading || isProfileLoading)
    return <LoadingPage />;
  if (requiredFilesError || projectError || profileError) return <ErrorPage />;

  const hasEditorAccess =
    project?.users.find((p) => p.user_id === profile.user_id).relationship !==
    "viewer";
  const completedRequiredFiles =
    requiredFiles?.filter(({ completed }) => completed) || [];
  const incompletedRequiredFiles =
    requiredFiles?.filter(({ completed }) => !completed) || [];

  const tabs = [
    {
      label: "All",
      value: "all",
      content: [...incompletedRequiredFiles, ...completedRequiredFiles].map(
        (file) => (
          <File
            key={file.file_id}
            {...file}
            projectId={projectId}
            hasEditorAccess={hasEditorAccess}
          />
        )
      ),
    },
    {
      label: "Pending",
      value: "pending",
      content: [...incompletedRequiredFiles].map((file) => (
        <File
          key={file.file_id}
          {...file}
          projectId={projectId}
          hasEditorAccess={hasEditorAccess}
        />
      )),
    },
    {
      label: "Completed",
      value: "completed",
      content: [...completedRequiredFiles].map((file) => (
        <File
          key={file.file_id}
          {...file}
          projectId={projectId}
          hasEditorAccess={hasEditorAccess}
        />
      )),
    },
  ];
  return (
    <div className="bg-white flex flex-col grow rounded-lg h-full p-4 gap-y-4">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Document Checklist" />
        <PageHelp
          title="Admin Space"
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <div>
                The Admin Space facilitates collaboration and ensures timely
                submission of your grant application.
              </div>
              <div>
                <h2 className="font-semibold">Action Items</h2>
                Manage tasks with a to-do list for each project. Create action
                items, assign them to users, set deadlines, and receive email
                reminders for pending tasks.
              </div>
              <div>
                <h2 className="font-semibold">Required Files</h2>
                Track all necessary files and their statuses in one place. Once
                a grant is selected in the Grant Space, we can also recommend
                which files are needed, reducing the time spent on managing
                document requirements.
              </div>
            </div>
          }
        />
      </div>
      <AddRecommendedFiles projectId={projectId} />
      <Table>
        <colgroup>
          <col className=""></col>
          <col className="w-10"></col>
        </colgroup>
        <TableCaption className="text-left border-y-[1px]">
          <button
            className="w-full text-left py-2 transition-colors hover:text-black hover:bg-gray-100"
            onClick={addRequiredFile}
          >
            + Add a new file
          </button>
        </TableCaption>

        <TableHeader>
          <TableRow>
            <TableHead>File</TableHead>
            <TableHead className="w-fit">Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>{tabs[0].content}</TableBody>
      </Table>
    </div>
  );
}
