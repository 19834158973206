import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { register } from "api/auth";
import useSWRMutation from "swr/mutation";
import { Input } from "@/components/styled/input";

export default function Register() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const {
    isMutating: isRegistering,
    error: registerError,
    trigger: registerAccount,
  } = useSWRMutation(
    `register`,
    () =>
      register({
        username: name,
        email: email,
        password: password,
        password_confirm: passwordConfirm,
      }),
    {
      onSuccess: () => {
        navigate("/verify-email", {
          state: { email: email, isEmailSent: true },
        });
      },
      onError: (e) => {
        if (e.response.status === 409) {
          setErrorMessage(
            "There already is an account created with this email."
          );
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
      },
    }
  );

  const sendRegister = async () => {
    if (password !== passwordConfirm) {
      setErrorMessage("Passwords do not match each other.");
      return;
    }

    registerAccount();
  };

  return (
    <div className="flex justify-center">
      <div className="max-w-lg w-full h-full bg-gradient-to-br p-1 rounded-2xl from-fuchsia to-blue">
        <div className="text-center py-4 text-2xl font-bold bg-offblack text-white rounded-t-2xl">
          Initium.AI
        </div>
        <div className="text-center py-10 bg-white rounded-b-2xl flex flex-col gap-y-4 px-4">
          <span className="font-bold">Register</span>
          <form
            className="flex flex-col gap-y-4"
            onSubmit={(e) => {
              e.preventDefault();
              sendRegister();
            }}
          >
            <Input
              placeholder="Name"
              value={name}
              onChange={(v) => setName(v)}
            />
            <Input
              placeholder="Email"
              value={email}
              onChange={(v) => setEmail(v)}
            />
            <Input
              placeholder="Password"
              type="password"
              value={password}
              onChange={(v) => setPassword(v)}
            />
            <Input
              placeholder="Confirm Password"
              type="password"
              value={passwordConfirm}
              onChange={(v) => setPasswordConfirm(v)}
            />
            <button
              disabled={
                !name.length ||
                !email.length ||
                !password.length ||
                !passwordConfirm.length
              }
              className="btn-fuchsia"
            >
              Register
            </button>
          </form>
          <span className="text-red-500 text-base h-4">{errorMessage}</span>
          <div className="flex flex-col items-center justify-center">
            <button
              className="text-base link-pink"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
            <button
              className="text-base link-pink"
              onClick={() => {
                navigate("/forgot-password");
              }}
            >
              Forgot Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
