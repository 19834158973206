import { Dialog } from "@/components/styled/dialog";
import { PageHelp } from "@/components/styled/pageHelp";
import { DialogClose } from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  add_new_review,
  delete_file_review,
  list_reviews,
} from "api/review_space";
import { LoadingPage, LoadingSpinner, Title } from "components";
import { useDropzone } from "react-dropzone";
import { FaCloudUploadAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";

const StatusPill = (status) => {
  if (status === "Not Started") {
    return (
      <div className="text-white font-semibold bg-gray-600 px-2 py-1 rounded-full text-center w-fit">
        Not Started
      </div>
    );
  } else if (status === "In Progress") {
    return (
      <div className="text-white font-semibold bg-yellow-600 px-2 py-1 rounded-full text-center w-fit">
        In Progress
      </div>
    );
  } else if (status === "Failed") {
    return (
      <div className="text-white font-semibold bg-red-600 px-2 py-1 rounded-full text-center w-fit">
        Failed
      </div>
    );
  } else if (status === "Processed") {
    return (
      <div className="text-white font-semibold bg-green-600 px-2 py-1 rounded-full text-center w-fit">
        Ready
      </div>
    );
  }
};

export default function ListReviews() {
  const { projectId } = useParams();

  const {
    data: reviews,
    isLoading: isReviewsLoading,
    error: reviewsError,
  } = useSWR(`list_reviews/${projectId}`, () =>
    list_reviews({ project_id: projectId })
  );

  const {
    isLoading: isDeletingReview,
    error: deleteReviewError,
    trigger: deleteReview,
  } = useSWRMutation(
    `delete_review`,
    (key, { arg }) =>
      delete_file_review({ project_id: projectId, file_id: arg }),
    {
      onSuccess: () => {
        mutate(`list_reviews/${projectId}`);
      },
    }
  );

  const { acceptedFiles, getRootProps, getInputProps, open, isDragActive } =
    useDropzone({
      accept: { "application/pdf": [".pdf"], "application/msword": [".docx"] },
      maxFiles: 1,
      noClick: true,
      noKeyboard: true,
    });

  const {
    data: fileData,
    isMutating: isFileUploading,
    error: isFileUploadError,
    trigger: uploadFile,
    reset: resetFile,
  } = useSWRMutation(`add_new_review/${projectId}`, (key, { arg }) => {
    return add_new_review(arg);
  });

  const onUploadFile = () => {
    const { name, type } = acceptedFiles[0];
    uploadFile({
      project_id: projectId,
      file_type: type,
      file_name: name,
      file: acceptedFiles[0],
    });
  };

  if (isReviewsLoading) return <LoadingPage />;

  return (
    <div className="bg-white grow rounded-lg h-full p-4">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Proposal Reviews" />
        <PageHelp
          title="Review Space"
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <div>
                Red Team Reviews provide quick feedback on your proposals. In
                just a few minutes, you'll receive a summary of key takeaways,
                along with an overview of strengths, areas for improvement, and
                specific feedback from Reviewers 1 and 3 (because we all know
                Reviewer 2 never actually read the paper!). We currently accept
                only PDF and DOCX files under 10MB.
              </div>
            </div>
          }
        />
      </div>
      <div className="flex justify-end">
        <Dialog
          trigger={<span className="btn-fuchsia text-base">Upload a file</span>}
          title={"Upload a file"}
          content={
            isFileUploading ? (
              <div
                className={
                  "w-full p-8 border-4 border-dashed rounded-lg flex gap-x-4 items-center"
                }
              >
                <LoadingSpinner />
                Uploading...
              </div>
            ) : fileData ? (
              <div
                className={
                  "w-full p-8 border-4 border-dashed rounded-lg flex flex-col gap-y-4 items-center"
                }
              >
                <div>Successfully Uploaded!</div>
                <button onClick={resetFile} className="btn-fuchsia text-base">
                  Upload another file.
                </button>
                <Link
                  to={`/project/${projectId}/review-space`}
                  className="text-base hover:underline text-fuchsia"
                >
                  Check the status.
                </Link>
              </div>
            ) : (
              <div
                className={
                  "w-full p-8 border-4 border-dashed rounded-lg " +
                  (isDragActive ? "border-fuchsia" : "border-zinc-200")
                }
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {acceptedFiles.length ? (
                  <div className="flex flex-col items-center justify-center ">
                    <FaCloudUploadAlt className="w-20 h-20" />
                    <p className="text-xl">{acceptedFiles[0].path}</p>
                    <span className="h-6"></span>
                    <button
                      className="btn-fuchsia text-xl"
                      onClick={onUploadFile}
                    >
                      Click to upload file
                    </button>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center ">
                    <FaCloudUploadAlt className="w-20 h-20" />
                    <p className="text-xl">Drag and drop a PDF or DOCX here</p>
                    <p>or</p>
                    <button onClick={open} className="btn-offblack text-xl">
                      Click to browse files
                    </button>
                  </div>
                )}
              </div>
            )
          }
        />
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>File Name</TableHead>
            <TableHead>Status</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {reviews.map((review) => (
            <TableRow className="group" key={review.id}>
              <TableCell>
                <Link to={`/project/${projectId}/review-space/${review.id}`}>
                  {review.name}
                </Link>
              </TableCell>
              <TableCell>{StatusPill(review.status)}</TableCell>
              <TableCell>
                <Dialog
                  className="hover:text-fuchsia invisible group-hover:visible"
                  trigger={<MdDelete className="w-4 h-4" />}
                  title={<div>Are you sure you want to delete this file?</div>}
                  content={
                    <div>
                      <div>The file will be permanently deleted.</div>
                      <div className="flex justify-between mt-8">
                        <DialogClose asChild>
                          <button className="text-zinc-600 hover:underline">
                            Cancel
                          </button>
                        </DialogClose>
                        <button
                          className="text-red-600 hover:underline"
                          disabled={isDeletingReview}
                          onClick={() => deleteReview(review.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
