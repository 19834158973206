import { Title } from "components";
import Markdown from "react-markdown";
export default function FileSummary({ review_outline, summary }) {
  return (
    <div className="flex flex-col overflow-y-scroll mb-8 gap-y-12">
      <div>
        <Title className="text-2xl" value="Outline" />
        <div className="text-base">
          <Markdown>{review_outline}</Markdown>
        </div>
      </div>
      <div>
        <Title className="text-2xl" value="Summary" />
        <div className="text-base">{summary}</div>
      </div>
    </div>
  );
}
