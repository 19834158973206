import { ScrollArea } from "@/components/ui/scroll-area";
import { get_project_calls } from "api";
import useSWR from "swr";
import Call from "./Call";
import { useParams } from "react-router-dom";
import { ErrorPage, LoadingPage } from "components";

export default function ChooseACall({projectId}) {

  const {
    data: calls,
    isLoading: isCallsLoading,
    error: isCallsError,
  } = useSWR(`get_project_calls/${projectId}`, async () => {
    const response = await get_project_calls({ project_id: projectId });
    if (typeof response === "string")
      return { recommendedCalls: [], bookmarkedCalls: [] };
    const { calls } = response;
    const recommendedCalls = calls.filter((c) => !c.bookmarked);
    const bookmarkedCalls = calls.filter((c) => c.bookmarked);
    return {
      recommendedCalls: recommendedCalls,
      bookmarkedCalls: bookmarkedCalls,
    };
  });

  if (isCallsLoading) return <LoadingPage />;
  if (isCallsError) return <ErrorPage />;
  return (
    <>
      <div className="text-sm">
        You haven't selected a call yet. Please select a call from your
        bookmarks below or find another call.
      </div>
      <ScrollArea className="px-4">
        <div className="flex flex-col gap-y-8">
          {calls.bookmarkedCalls?.map((call) => (
            <Call {...call} key={call.call_id} projectId={projectId} />
          ))}
        </div>
      </ScrollArea>
    </>
  );
}
