import Markdown from "react-markdown";
import { MdFace, MdFace4 } from "react-icons/md";
import reactStringReplace from "react-string-replace";

export default function InitiumChatMessage({ sender, message, creator_name }) {
  const isSender1 = sender === "Researcher 1";
  const formattedCreatorName = `@${creator_name.replace(" ", "_")}`;

  let re = new RegExp(
    String.raw`(${formattedCreatorName}|@Researcher_1|@Researcher_3)`,
    "g"
  );

  const formattedMessage = reactStringReplace(message, re, (match, i) => {
    return (
      <span key={i} className="font-semibold text-blue">
        {match}
      </span>
    );
  });

  return (
    <div className="w-2/3 flex gap-x-2 items-end">
      <div
        className={`rounded-full bg-pastel-blue border-2 h-fit w-fit translate-y-1/2`}
      >
        {isSender1 ? (
          <MdFace4 className="w-8 h-8" />
        ) : (
          <MdFace className="w-8 h-8" />
        )}
      </div>
      <div className="bg-pastel-blue flex flex-col px-4 py-1 rounded-2xl rounded-es-none text-sm">
        <div
          className={`border-b-1 border-offblack font-semibold text-base text-blue`}
        >
          {sender}
        </div>
        <div>
          <Markdown>{message}</Markdown>
        </div>
      </div>
    </div>
  );
}
