import {
  add_required_file,
  get_required_files,
  recommend_required_files,
} from "api";

import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { Dialog } from "@/components/styled/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { LoadingPage, LoadingSpinner } from "components";

export default function AddRecommendedFiles({ projectId }) {
  const {
    data: recommendedRequiredFiles,
    error: recommendRequiredFilesError,
    isLoading: isRecommendedRequiredFilesLoading,
  } = useSWR(`recommend_required_files/${projectId}`, () =>
    recommend_required_files({ project_id: projectId })
  );

  const {
    data: requiredFiles,
    isLoading: isRequiredFilesLoading,
    error: requiredFilesError,
  } = useSWR(`get_required_files/${projectId}`, () =>
    get_required_files({ project_id: projectId })
  );

  const { error: addRequiredFileError, trigger: addRequiredFile } =
    useSWRMutation(
      `add_required_file`,
      async (key, { arg: { description, file_info } }) => {
        await add_required_file({
          project_id: projectId,
          file_info: file_info,
          description: description,
        });
        return { description, file_info };
      },
      {
        onSuccess: ({ description, file_info }) => {
          mutate(
            `recommend_required_files/${projectId}`,
            (d) =>
              d?.filter(
                (file) =>
                  file.description !== description &&
                  file.file_info !== file_info
              ),
            {
              revalidate: false,
            }
          );
          mutate(`get_required_files/${projectId}`);
        },
      }
    );

  const isLoading = isRecommendedRequiredFilesLoading || isRequiredFilesLoading;
  if (isLoading)
    return (
      <div className="flex justify-end">
        <button
          disabled={true}
          className="flex items-center gap-x-2 opacity-70"
        >
          Loading recommended files <LoadingSpinner />
        </button>
      </div>
    );

  const newRecommendedRequiredFiles = recommendedRequiredFiles?.filter(
    ({ file_info, description }) =>
      !requiredFiles.some(
        (file) =>
          file.file_info === file_info && file.description === description
      )
  );

  return (
    <div className="flex justify-end">
      <Dialog
        trigger={<span className="btn-fuchsia">See recommended files</span>}
        title="Add recommended files"
        contentClassName="max-w-2xl"
        content={
          <div className="flex flex-col gap-y-4 max-h-96 overflow-y-scroll p-2">
            {newRecommendedRequiredFiles?.length > 0 ? (
              newRecommendedRequiredFiles.map(({ description, file_info }) => (
                <div
                  key={file_info}
                  className="rounded-md p-1 gap-x-1 flex bg-pastel-blue hover:ring-2 ring-fuchsia"
                  onClick={() => addRequiredFile({ description, file_info })}
                >
                  <Checkbox className="bg-white" />
                  <div>
                    <div className="font-semibold">{file_info}</div>
                    <div className="">{description}</div>
                  </div>
                </div>
              ))
            ) : (
              <span>We do not have any suggested files.</span>
            )}
          </div>
        }
      />
    </div>
  );
}
