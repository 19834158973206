import { FaRegBookmark, FaExternalLinkAlt } from "react-icons/fa";
import { bookmark_project_call, select_call } from "api";
import useSWRMutation from "swr/mutation";
import { LoadingSpinner } from "components";
import { mutate } from "swr";
import { Dialog } from "@/components/styled/dialog";
import { remove_timestamp } from "@/lib/utils";
import { ExternalLink } from "@/components/styled/external-link";
import { MdCheckBoxOutlineBlank } from "react-icons/md";

export default function Call({
  title,
  agency,
  description,
  close_date,
  bookmarked,
  call_id,
  url,
  posted_date,
  projectId,
}) {
  const {
    isMutating: selectingCall,
    error: selectCallError,
    trigger: selectCall,
  } = useSWRMutation(
    `select_call/${projectId}/${call_id}`,
    async () => select_call({ project_id: projectId, call_id: call_id }),
    {
      onSuccess: () => {
        mutate(`get_project/${projectId}`)
      },
    }
  );

  const newCloseDate = remove_timestamp(close_date);
  const newPostedDate = remove_timestamp(posted_date);

  return (
    <Dialog
      trigger={
        <div className="flex flex-row border-[1px] rounded-lg p-4 gap-x-4 hover:border-fuchsia text-left">
          <div className="flex flex-col">
            <div>
              {url ? (
                <ExternalLink url={url}>{title}</ExternalLink>
              ) : (
                <span className="text-2xl underline font-semibold">
                  {title}
                </span>
              )}
            </div>

            <div>{agency}</div>
            <div className="text-base">Posted: {newPostedDate}</div>
            <div className="text-base">Closes: {newCloseDate}</div>

            <div className="break-words rounded-md text-base">
              {description.substring(0, 300) + "..."}
            </div>
          </div>
          <div className="flex flex-col gap-y-12">
            <div className="font-semibold underline">Select</div>
            <div
              className="flex justify-center items-center hover:text-fuchsia hover:underline text-base"
              onClick={(e) => {
                e.stopPropagation();
                selectCall();
              }}
            >
              <MdCheckBoxOutlineBlank
                className={"rounded accent-fuchsia w-8 h-8"}
              />
            </div>
          </div>
        </div>
      }
      contentClassName="max-w-4xl max-h-screen overflow-y-scroll"
      content={
        <div className="text-black flex flex-col gap-y-4">
          <div>
            {url ? (
              <ExternalLink url={url}>{title}</ExternalLink>
            ) : (
              <span className="text-2xl underline font-semibold">{title}</span>
            )}
          </div>
          <div>{agency}</div>
          <div>
            <span className="font-semibold">Posted:</span> {newPostedDate}
          </div>
          <div>
            <span className="font-semibold">Closes:</span> {newCloseDate}
          </div>

          <div>{description}</div>
        </div>
      }
    />
  );
}
